<template>
  <modal v-loading="loaders.supportGroupSubCategory" :show.sync="$store.state.modals.editSupportGroupSubCategory">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Edit Support Group Sub Category</h1>
    <support-group-sub-category-form
      @updateSupportGroupSubCategory="updateSupportGroupSubCategory"
      :support-group-sub-category-data-to-edit="response.supportGroupSubCategory"
      ref="SupportGroupSubCategoryForm">
    </support-group-sub-category-form>
  </modal>
</template>

<script>

import SupportGroupSubCategoryForm
  from "@/views/Pages/ContentSection/SupportGroup/SupportGroupSubCategory/SupportGroupSubCategoryForm"

export default {
  name: "EditSupportGroupSubCategoryModal",
  props: ['id'],
  components: {
    SupportGroupSubCategoryForm
  },
  data() {
    return {
      loaders: {
        supportGroupSubCategory: false
      },
      editSubCategoryId: '',
      response: {
        supportGroupSubCategory: ''
      }
    }
  },
  watch: {
    /**
     * Watcher to check newVal of Id prop
     * @param {number} newVal - New Value of the Id prop
     */
    id: function (newVal) {
      if (newVal != null) {
        this.editSubCategoryId = newVal
        this.getSupportGroupSubCategory()
      }
    }
  },
  methods: {
    /**
     * call to fetch all support group categories
     */
    fetchCategories() {
      this.$refs.SupportGroupSubCategoryForm.getAllSupportGroupCategories()
    },

    /**
     * Get the relevant support group sub category
     */
    getSupportGroupSubCategory() {
      let vm = this
      vm.loaders.supportGroupSubCategory = true
      const payload = { id: vm.editSubCategoryId }
      vm.$store.dispatch('_getSupportGroupSubCategory', payload)
      .then(response => {
        vm.response.supportGroupSubCategory = response.data.data
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Edit Support Group Sub Category',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.supportGroupSubCategory = false
      })
    },

    /**
     * Update support group sub category
     * @param {event} event - Event Object
     */
    updateSupportGroupSubCategory(event) {
      let vm = this
      vm.loaders.supportGroupSubCategory = true
      const formData = {}
      Object.assign(formData,event)
      formData.id = vm.editSubCategoryId
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      vm.$store.dispatch('_updateSupportGroupSubCategory', {config, formData})
      .then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Support Group Sub Category updated successfully.'
        })
        vm.$store.commit('hideEditSupportGroupSubCategoryModal')
        vm.$emit('updateSupportGroupSubCategories')
      })
      .catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Edit Support Group Sub Category',
          dangerouslyUseHTMLString: true,
          message: message
        })
      })
      .finally(() => {
        vm.loaders.supportGroupSubCategory = false
      })
    },

    /**
     * Generate error message from array of errors
     * @param arr - Array of errors message
     * @returns {string} message
     */
    generateErrorMessage(arr) {
      let message = ''
      for (let item in arr) {
        message += '<li>' + arr[item] + '</li>'
      }
      return message
    },
  }
}
</script>

<style scoped>

</style>
