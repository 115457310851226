<template>
  <modal v-loading="loaders.supportGroupSubCategory" :show.sync="$store.state.modals.addSupportGroupSubCategory">
    <h1 slot="header" class="modal-title pt-2" id="modal-title-default">Add Support Group Sub Category</h1>
    <support-group-sub-category-form
      @storeSupportGroupSubCategory="storeSupportGroupSubCategory"
      ref="SupportGroupSubCategoryForm">
    </support-group-sub-category-form>
  </modal>
</template>

<script>

import SupportGroupSubCategoryForm
  from "@/views/Pages/ContentSection/SupportGroup/SupportGroupSubCategory/SupportGroupSubCategoryForm"
export default {
  name: "AddSupportGroupSubCategoryModal",
  components: {
    SupportGroupSubCategoryForm
  },
  data() {
    return {
      loaders: {
        supportGroupSubCategory: false
      }
    }
  },
  methods: {
    /**
     * call to fetch all support group categories
     */
    fetchCategories() {
      this.$refs.SupportGroupSubCategoryForm.getAllSupportGroupCategories()
    },

    /**
     * Store the new support group sub category
     * @param {event} event - Event Object
     */
    storeSupportGroupSubCategory(event) {
      let vm = this
      vm.loaders.supportGroupSubCategory = true
      const formData = {}
      Object.assign(formData,event)
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      vm.$store.dispatch('_storeSupportGroupSubCategory', {config, formData})
      .then(response => {
        vm.$notify.success({
          title: 'Success',
          message: 'Support Group Sub Category created successfully.'
        })
        this.$refs.SupportGroupSubCategoryForm.resetForm()
        vm.$store.commit('hideAddSupportGroupSubCategoryModal')
        vm.$emit('updateSupportGroupSubCategories')
      })
      .catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Add Support Group Sub Category',
          dangerouslyUseHTMLString: true,
          message: message
        })
      })
      .finally(() => {
        vm.loaders.supportGroupSubCategory = false
      })
    },

    /**
     * Generate error message from array of errors
     * @param arr - Array of errors message
     * @returns {string} message
     */
    generateErrorMessage(arr) {
      let message = ''
      for (let item in arr) {
        message += '<li>' + arr[item] + '</li>'
      }
      return message
    },
  }
}
</script>

<style scoped>

</style>
