<template>
  <div>
    <validation-observer ref="supportGroupSubCategoryForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input name="category" label="Support Group Category*">
          <el-select
            class="w-100"
            v-model="request.supportGroupSubCategoryForm.category"
            placeholder="Select Category">
            <el-option
              class="select-danger"
              :value="0"
              label="Select Category"
              key="0">
            </el-option>
            <el-option
              v-for="option in response.categories"
              class="select-danger max-width-450"
              :value="option.id"
              :label="option.name"
              :key="option.id">
            </el-option>
          </el-select>
        </base-input>
        <base-input
          type="text"
          :rules="{required: true}"
          name="name"
          v-model="request.supportGroupSubCategoryForm.name"
          label="Support Group Sub Category Name*"
          placeholder="Name">
        </base-input>
        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="hideForm">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            Save
          </base-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "SupportGroupSubCategoryForm",
  props: ['supportGroupSubCategoryDataToEdit'],
  data() {
    return {
      request: {
        supportGroupSubCategoryForm: {
          name: '',
          category: 0
        }
      },
      response: {
        categories: [],
      },
    }
  },
  watch: {
    /**
     * Save new value to supportGroupSubCategoryDataToEdit property
     * @param newVal - New Value of supportGroupSubCategoryDataToEdit property
     */
    supportGroupSubCategoryDataToEdit: function (newVal) {
      this.request.supportGroupSubCategoryForm.name = newVal.name ?? ''
      this.request.supportGroupSubCategoryForm.category = newVal.support_group_category_id ?? 0
    }
  },
  methods: {

    /**
     * Fetch all the support group categories
     */
    getAllSupportGroupCategories() {
      let vm = this
      vm.$store.dispatch('_getAllSupportGroupCategories')
      .then(response => {
        vm.response.categories = response.data.data
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Support Group Sub Category',
          message: message
        })
      })
    },

    /**
     * Validates the form field, submit it and emit relevant function
     */
    async submitForm() {
      let vm = this
      const isSupportGroupSubCategoryFormValid = await this.$refs['supportGroupSubCategoryForm'].validate()
      if (isSupportGroupSubCategoryFormValid) {
        if(this.$store.state.modals.addSupportGroupSubCategory) {
          vm.$emit('storeSupportGroupSubCategory', vm.request.supportGroupSubCategoryForm)
        } else {
          vm.$emit('updateSupportGroupSubCategory', vm.request.supportGroupSubCategoryForm)
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

    /**
     * Hide the form
     */
    hideForm() {
      if(this.$store.state.modals.addSupportGroupSubCategory) {
        this.resetForm()
        this.$store.commit('hideAddSupportGroupSubCategoryModal')
      } else {
        this.$store.commit('hideEditSupportGroupSubCategoryModal')
      }
    },

    /**
     * Reset the Support group sub category form fields
     */
    resetForm(){
      this.request.supportGroupSubCategoryForm.name = ''
      this.request.supportGroupSubCategoryForm.category = ''
      this.$refs.supportGroupSubCategoryForm.reset()
    }
  }
}
</script>

<style scoped>

.max-width-450{
  max-width: 450px;
}

</style>
